import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Reports} from '../../../helpers/Urls';

const intialState = {
  isLoading: false,
  isFetching: false,
  totalTripCounts: {},
  trips_data: {},
};

export const GET_TOTAL_TRIP_COUNTS = 'GET_TOTAL_TRIP_COUNTS';
export const GET_TOTAL_TRIP_COUNTS_COMPLETED =
  'GET_TOTAL_TRIP_COUNTS_COMPLETED';
export const GET_TOTAL_TRIP_COUNTS_FAILED = 'GET_TOTAL_TRIP_COUNTS_FAILED';

export const FETCH_CUSTOMER_TRIPS = 'FETCH_CUSTOMER_TRIPS';
export const FETCH_CUSTOMER_TRIPS_COMPLETED = 'FETCH_CUSTOMER_TRIPS_COMPLETED';
export const FETCH_CUSTOMER_TRIPS_FAILED = 'FETCH_CUSTOMER_TRIPS_FAILED';

//...............filter_............
export const FETCH_YEARLY_CUSTOMER_TRIPS = 'FETCH_YEARLY_CUSTOMER_TRIPS';
export const FETCH_YEARLY_CUSTOMER_TRIPS_COMPLETED =
  'FETCH_YEARLY_CUSTOMER_TRIPS_COMPLETED';
export const FETCH_YEARLY_CUSTOMER_TRIPS_FAILED =
  'FETCH_YEARLY_CUSTOMER_TRIPS_FAILED';
//...............filter_............

export const fetchTotalTrips = (months) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GET_TOTAL_TRIP_COUNTS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Reports.fetchTotalTripsURL(months),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: GET_TOTAL_TRIP_COUNTS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: GET_TOTAL_TRIP_COUNTS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_TOTAL_TRIP_COUNTS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

//...............filter_............
export const fetchYearlyReportFilter = (type = '') => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_YEARLY_CUSTOMER_TRIPS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Reports.fetchYearlyReportFilterURL(type),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_YEARLY_CUSTOMER_TRIPS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_YEARLY_CUSTOMER_TRIPS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_YEARLY_CUSTOMER_TRIPS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
//...............filter_............

export const fetchCustomerTotalTrips = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_CUSTOMER_TRIPS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Reports.fetchCustomerTotalTripsURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_CUSTOMER_TRIPS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_CUSTOMER_TRIPS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_CUSTOMER_TRIPS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = intialState, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_CUSTOMER_TRIPS:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        error: '',
      };
    case FETCH_CUSTOMER_TRIPS_COMPLETED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        error: '',
        trips_data: payload?.data || {},
      };
    case FETCH_CUSTOMER_TRIPS_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: payload.error,
      };

    //...............filter_............
    case FETCH_YEARLY_CUSTOMER_TRIPS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_YEARLY_CUSTOMER_TRIPS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        error: '',
        customerReport: Object.assign({}, payload),
      };
    case FETCH_YEARLY_CUSTOMER_TRIPS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload?.error,
        customerReport: {},
      };
    //...............filter_............

    case GET_TOTAL_TRIP_COUNTS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case GET_TOTAL_TRIP_COUNTS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        error: '',
        totalTripCounts: payload || {},
      };
    case GET_TOTAL_TRIP_COUNTS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        totalTripCounts: {},
      };
    default:
      return state;
  }
};
