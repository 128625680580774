export default (state = [], action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      if (payload.customer_team_type) {
        return payload.customer_team_type;
      }
      return state;
    }
  }
};
