import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Records} from '../../../helpers/Urls';
import axios from 'axios';
import {merge} from 'lodash';

import {ADD_CONSIGNOR_COMPLETED, EDIT_CONSIGNOR_COMPLETED} from '../../record';
import UseAbortApiCall from '../../../Common/Hooks/use-abort';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  consignorIds: [],
  consignorCount: 0,
  search: '',
  headerVisible: false,
  resetPage: false,
};

const FETCH_CONSIGNOR_DETAILS = 'FETCH_CONSIGNOR_DETAILS';
const FETCH_CONSIGNOR_DETAILS_COMPLETED = 'FETCH_CONSIGNOR_DETAILS_COMPLETED';
const FETCH_CONSIGNOR_DETAILS_FAILED = 'FETCH_CONSIGNOR_DETAILS_FAILED';

const TOGGLE_ADD_CONSIGNOR_HEADER = 'TOGGLE_ADD_CONSIGNOR_HEADER ';
const RESET_PAGE = 'RESET_PAGE';

export const toggleAddConsignorHeader = () => {
  return (dispatch) => {
    dispatch({type: TOGGLE_ADD_CONSIGNOR_HEADER});
  };
};

export const fetchConsignorRecords = (page, search, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    let {cancelApiCall} = UseAbortApiCall('cancelPreviousRequestFetchDoList');
    try {
      dispatch({type: FETCH_CONSIGNOR_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        cancelToken: cancelApiCall(),
        url: Records.fetchConsignorRecordsURL(page, search),
      });
      // console.log('response --FETCH_VENDOR_DETAILS_COMPLETED--', response);
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }

      if (status === true) {
        dispatch({type: FETCH_CONSIGNOR_DETAILS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_CONSIGNOR_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return {
          error: true,
        };
      }
      dispatch({
        type: FETCH_CONSIGNOR_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

const setConsignorsIds = (state, payload = {}) => {
  const {consignor_list} = payload;
  if (state?.consignorIds?.length < 30) {
    const updatedState = [...state.consignorIds, ...consignor_list];
    return [...updatedState];
  } else return state.consignorIds;
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_CONSIGNOR_DETAILS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_CONSIGNOR_DETAILS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        consignorIds: payload?.consignor_list,
        consignorCount: payload?.total,
        total: payload?.total,
        search: payload.query_string,
      };
    case FETCH_CONSIGNOR_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        consignorIds: [],
      };
    case ADD_CONSIGNOR_COMPLETED:
      return {
        ...state,
        consignorIds: payload?.consignor_list,
      };
    case TOGGLE_ADD_CONSIGNOR_HEADER:
      return {...state, headerVisible: !state.headerVisible};
    case RESET_PAGE:
      return {
        ...state,
        resetPage: !state.resetPage,
      };
    default:
      return state;
  }
};
