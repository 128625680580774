import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Dashboard} from '../../../helpers/Urls';

const intialState = {
  isFetching: false,
  dashboardReport: {},
  isDataFetching: false,
};

export const FETCH_DASHBOARD_DATA = 'FETCH_DASHBOARD_DATA';
export const FETCH_DASHBOARD_DATA_COMPLETED = 'FETCH_DASHBOARD_DATA_COMPLETED';
export const FETCH_DASHBOARD_DATA_FAILED = 'FETCH_DASHBOARD_DATA_FAILED';

export const FETCH_TASK_MANAGEMENT_DATA = 'FETCH_TASK_MANAGEMENT_DATA';
export const FETCH_TASK_MANAGEMENT_COMPLETED =
  'FETCH_TASK_MANAGEMENT_COMPLETED';
export const FETCH_TASK_MANAGEMENT_FAILED = 'FETCH_TASK_MANAGEMENT_FAILED';

export const fetchDashboardReport = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DASHBOARD_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Dashboard.fetchDashboardReportURL(),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_DASHBOARD_DATA_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_DASHBOARD_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_DASHBOARD_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchTaskManagementData = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TASK_MANAGEMENT_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Dashboard.fetchTaskManagementDataURL(),
      });
      const {data, status} = response || {};

      if (status) {
        dispatch({
          type: FETCH_TASK_MANAGEMENT_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_TASK_MANAGEMENT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TASK_MANAGEMENT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = intialState, action) => {
  const {type, payload = {}} = action || {};
  switch (type) {
    case FETCH_DASHBOARD_DATA:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_DASHBOARD_DATA_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        error: '',
        dashboardReport: Object.assign({}, payload),
      };
    case FETCH_DASHBOARD_DATA_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload?.error,
        dashboardReport: {},
      };

    case FETCH_TASK_MANAGEMENT_DATA:
      return {
        ...state,
        isDataFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_TASK_MANAGEMENT_COMPLETED:
      return {
        ...state,
        isDataFetching: false,
        hasError: false,
        error: '',
      };
    case FETCH_TASK_MANAGEMENT_FAILED:
      return {
        ...state,
        isDataFetching: false,
        hasError: true,
        error: payload?.error,
      };

    default:
      return state;
  }
};
