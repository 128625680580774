import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Bookings} from '../../../helpers/Urls';

export const CREATE_BOOKING = 'CREATE_BOOKING';
export const CREATE_BOOKING_COMPLETED = 'CREATE_BOOKING_COMPLETED';
export const CREATE_BOOKING_FAILED = 'CREATE_BOOKING_FAILED';

export const FETCH_CITIES_BY_CUSTOMER = 'FETCH_CITIES_BY_CUSTOMER';
export const FETCH_CITIES_BY_CUSTOMER_COMPLETED =
  'FETCH_CITIES_BY_CUSTOMER_COMPLETED';
export const FETCH_CITIES_BY_CUSTOMER_FAILED =
  'FETCH_CITIES_BY_CUSTOMER_FAILED';

export const FETCH_PROCURED_BY = 'FETCH_PROCURED_BY';
export const FETCH_PROCURED_BY_COMPLETED = 'FETCH_PROCURED_BY_COMPLETED';
export const FETCH_PROCURED_BY_FAILED = 'FETCH_PROCURED_BY_FAILED';

const INITIAL_STATE = {
  isFetching: false,
  isRequesting: false,
  isProcuredByLoading: false,
  loadProcuredData: {},
  cityHierarchy: {},
};

export const fetchCitiesByCustomer = (customerCode) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_CITIES_BY_CUSTOMER});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Bookings.fetchCitiesByCustomerURL(customerCode),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_CITIES_BY_CUSTOMER_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_CITIES_BY_CUSTOMER_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_CITIES_BY_CUSTOMER_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const fetchProcuredBy = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_PROCURED_BY});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Bookings.fetchProcuredByURL(),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_PROCURED_BY_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_PROCURED_BY_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_PROCURED_BY_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const createBooking = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CREATE_BOOKING});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.createBookingURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: CREATE_BOOKING_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: CREATE_BOOKING_FAILED,
          payload: {error: message, data},
        });
      }
    } catch (err) {
      dispatch({
        type: CREATE_BOOKING_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload = {}} = action || {};
  switch (type) {
    case CREATE_BOOKING:
      return {...state, isFetching: true};
    case CREATE_BOOKING_COMPLETED:
      return {...state, isFetching: false};
    case CREATE_BOOKING_FAILED:
      return {...state, isFetching: false, error: payload?.error, ...payload};
    case FETCH_CITIES_BY_CUSTOMER:
      return {...state, isRequesting: true};
    case FETCH_CITIES_BY_CUSTOMER_COMPLETED:
      return {
        ...state,
        isRequesting: false,
        cityHierarchy: payload.city_hierarchy,
      };
    case FETCH_CITIES_BY_CUSTOMER_FAILED:
      return {...state, isRequesting: false, error: payload?.error, ...payload};
    case FETCH_PROCURED_BY:
      return {...state, isProcuredByLoading: true};
    case FETCH_PROCURED_BY_COMPLETED:
      return {...state, isProcuredByLoading: false, loadProcuredData: payload};
    case FETCH_PROCURED_BY_FAILED:
      return {...state, isProcuredByLoading: false};
    default:
      return state;
  }
};
