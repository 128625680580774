import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Reports} from '../../../helpers/Urls';

const intialState = {
  isFetching: false,
  customerReport: {},
};

export const FETCH_CUSTOMER_REPORTS = 'FETCH_CUSTOMER_REPORTS';
export const FETCH_CUSTOMER_REPORTS_COMPLETED =
  'FETCH_CUSTOMER_REPORTS_COMPLETED';
export const FETCH_CUSTOMER_REPORTS_FAILED = 'FETCH_CUSTOMER_REPORTS_FAILED';

export const DOWNLOAD_CUSTOMER_REPORTS = 'DOWNLOAD_CUSTOMER_REPORTS';
export const DOWNLOAD_CUSTOMER_REPORTS_COMPLETED =
  'DOWNLOAD_CUSTOMER_REPORTS_COMPLETED';
export const DOWNLOAD_CUSTOMER_REPORTS_FAILED =
  'DOWNLOAD_CUSTOMER_REPORTS_FAILED';

export const fetchCustomerReport = (activeTab) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_CUSTOMER_REPORTS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Reports.fetchCustomerReportURL(activeTab),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_CUSTOMER_REPORTS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_CUSTOMER_REPORTS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_CUSTOMER_REPORTS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = intialState, action) => {
  const {type, payload = {}} = action || {};
  switch (type) {
    case FETCH_CUSTOMER_REPORTS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_CUSTOMER_REPORTS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        customerReport: payload,
      };
    case FETCH_CUSTOMER_REPORTS_FAILED:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};
