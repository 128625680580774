import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {DO} from '../../../helpers/Urls';
import UseAbortApiCall from '../../../Common/Hooks/use-abort';
import {ADD_CITY_RECORD_COMPLETED} from '../../record';

import axios from 'axios';
let cancelPreviousRequest = null;
let cancelFetchDoIdPreviousRequest = null;

const INITIAL_STATE = {
  doData: {},
  doInitialData: {},
  doByIdData: {},
  isFetching: false,
  isFetchingDOList: false,
  isFetchDOListCanceled: false,
  isFetchingDOInitial: false,
  isFetchingDOById: false,
  requestingReleaseLoads: false,
  isRequestingConsignors: false,
  search: '',
  filters: {},
  assignVehicleLoading: false,
  onClearSearch: false,
  searchFeildData: '',
  newBeneficiaryId: null,
  InActiveVehicle: [],
  IsUnavailableVehicle: [],
  UnApprovedVehicle: [],
  callDoDataAPI: false,
  deletingTripPOD: false,
  checkingBillingEntity: false,
  billingEntityData: {},
  doByIdError: '',
  doListError: '',
};

const RECORD_SHORTAGE_DATA = 'RECORD_SHORTAGE_DATA';
const RECORD_SHORTAGE_DATA_COMPLETED = 'RECORD_SHORTAGE_DATA_COMPLETED';
const RECORD_SHORTAGE_DATA_FAILED = 'RECORD_SHORTAGE_DATA_FAILED';

const CREATE_DO = 'CREATE_DO';
const CREATE_DO_COMPLETED = 'CREATE_DO_COMPLETED';
const CREATE_DO_FAILED = 'CREATE_DO_FAILED';

const FETCH_DO_LIST = 'FETCH_DO_LIST';
const FETCH_DO_LIST_COMPLETED = 'FETCH_DO_LIST_COMPLETED';
const FETCH_DO_LIST_FAILED = 'FETCH_DO_LIST_FAILED';
const FETCH_DO_LIST_CANCELED = 'FETCH_DO_LIST_CANCELED';

const FETCH_DO_BY_ID = 'FETCH_DO_BY_ID';
const FETCH_DO_BY_ID_COMPLETED = 'FETCH_DO_BY_ID_COMPLETED';
const FETCH_DO_BY_ID_FAILED = 'FETCH_DO_BY_ID_FAILED';

const DELETE_OR_CLOSE_DO = 'DELETE_OR_CLOSE_DO';
const DELETE_OR_CLOSE_DO_COMPLETED = 'DELETE_OR_CLOSE_DO_COMPLETED';
const DELETE_OR_CLOSE_DO_FAILED = 'DELETE_OR_CLOSE_DO_FAILED';

const RELEASE_LOADS = 'RELEASE_LOADS';
const RELEASE_LOADS_COMPLETED = 'RELEASE_LOADS_COMPLETED';
const RELEASE_LOADS_FAILED = 'RELEASE_LOADS_FAILED';

const ASSIGN_VEHICLE = 'ASSIGN_VEHICLE';
const ASSIGN_VEHICLE_COMPLETED = 'ASSIGN_VEHICLE_COMPLETED';
const ASSIGN_VEHICLE_FAILED = 'ASSIGN_VEHICLE_FAILED';

const FETCH_DO_INITIAL_DATA = 'FETCH_DO_INITIAL_DATA';
const FETCH_DO_INITIAL_DATA_COMPLETED = 'FETCH_DO_INITIAL_DATA_COMPLETED';
const FETCH_DO_INITIAL_DATA_FAILED = 'FETCH_DO_INITIAL_DATA_FAILED';

const DELETE_TRIP_POD = 'DELETE_TRIP_POD';
const DELETE_TRIP_POD_COMPLETED = 'DELETE_TRIP_POD_COMPLETED';
const DELETE_TRIP_POD_FAILED = 'DELETE_TRIP_POD_FAILED';

const FETCH_CONSIGNORS_BY_CUSTOMER = 'FETCH_CONSIGNORS_BY_CUSTOMER';
const FETCH_CONSIGNORS_BY_CUSTOMER_COMPLETED =
  'FETCH_CONSIGNORS_BY_CUSTOMER_COMPLETED';
const FETCH_CONSIGNORS_BY_CUSTOMER_FAILED =
  'FETCH_CONSIGNORS_BY_CUSTOMER_FAILED';
// const RESET_PAGE = 'RESET_PAGE';

const HANDLE_CLEAR_SEARCH = 'HANDLE_CLEAR_SEARCH';
const EMPTY_SEARCH_INPUT_DATA = 'EMPTY_SEARCH_INPUT_DATA';
export const STORE_NEW_BENEFICIARY_ID = 'STORE_NEW_BENEFICIARY_ID';

const CHECK_BILLING_ENTITY = 'CHECK_BILLING_ENTITY';
const CHECK_BILLING_ENTITY_COMPLETED = 'CHECK_BILLING_ENTITY_COMPLETED';
const CHECK_BILLING_ENTITY_FAILED = 'CHECK_BILLING_ENTITY_FAILED';

const CALL_DO_DATA_API = 'CALL_DO_DATA_API';

export const callGetDoDataAPI = (callAPI) => {
  return (dispatch) => {
    dispatch({
      type: CALL_DO_DATA_API,
      payload: callAPI,
    });
  };
};

export const handleClearSearch = (clearSearch = false) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_CLEAR_SEARCH,
      payload: clearSearch,
    });
  };
};

export const handleSearchInputData = (searchData) => {
  return (dispatch) => {
    dispatch({
      type: EMPTY_SEARCH_INPUT_DATA,
      payload: searchData,
    });
  };
};
export const editDo = (requestData, id) => {
  return async (dispatch) => {
    let response = {};
    console.log(id);

    try {
      dispatch({type: 'EDIT_DO'});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: DO.editDO(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: CREATE_DO_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CREATE_DO_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CREATE_DO_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const storeNewBeneficiaryId = (data) => {
  return (dispatch) => {
    dispatch({
      type: STORE_NEW_BENEFICIARY_ID,
      payload: data,
    });
  };
};

export const createDO = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CREATE_DO});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: DO.fetchDOInitialDataURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: CREATE_DO_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CREATE_DO_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CREATE_DO_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchDoList = (search) => {
  return async (dispatch) => {
    let response = {};
    let {cancelApiCall} = UseAbortApiCall('cancelPreviousRequestFetchDoList');
    try {
      dispatch({type: FETCH_DO_LIST});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        cancelToken: cancelApiCall(),
        url: DO.fetchDoListURL(search),
        // data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_DO_LIST_COMPLETED,
          payload: {...data, search},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_DO_LIST_FAILED,
          payload: {
            error: Object.keys(response?.data).length === 0 ? '' : message,
          },
        });
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        dispatch({
          type: FETCH_DO_LIST_CANCELED,
          payload: true,
        });
        return {status: false, isFetchDOListCanceled: true};
      } else {
        dispatch({
          type: FETCH_DO_LIST_FAILED,
          payload: {error: err.message},
        });
      }
    }
    return response;
  };
};

export const fetchConsignorsByCustomer = (customerId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_CONSIGNORS_BY_CUSTOMER});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: DO.fetchConsignorsByCustomerURL(customerId),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_CONSIGNORS_BY_CUSTOMER_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_CONSIGNORS_BY_CUSTOMER_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_CONSIGNORS_BY_CUSTOMER_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchDOInitialData = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DO_INITIAL_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: DO.fetchDOInitialDataURL(),
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_DO_INITIAL_DATA_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_DO_INITIAL_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_DO_INITIAL_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchSummaryData = (id = '', method, value) => {
  return async (dispatch) => {
    let response = {};
    let newUrl =
      method === 'GET'
        ? '/trip/hold-trip-payment?trip_id=' + id
        : '/trip/hold-trip-payment';
    try {
      dispatch({type: 'FETCH_SUMMARY'});
      response = await doRequest({
        method: REQUEST_TYPE[method],
        url: newUrl,
        data: value,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: 'FETCH_SUMMARY_COMPLETED', payload: data});
      } else {
        const {message = 'Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

export const savetds = (value, id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: 'SAVE_TDS'});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: '/vendor/billing-entity',
        data: value,
      });
      const {data, status} = response || {};
      if (status) {
      } else {
        const {message = 'Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

export const checkBillingEntity = (pan_number) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CHECK_BILLING_ENTITY});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: DO.checkBillingEntityURL(pan_number),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: CHECK_BILLING_ENTITY_COMPLETED, payload: {...data}});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CHECK_BILLING_ENTITY_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CHECK_BILLING_ENTITY_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchDOById = (doId, search, tripId) => {
  return async (dispatch) => {
    let response = {};
    let addtripId = tripId ? `&trip_id=${tripId}` : '';
    let {cancelApiCall} = UseAbortApiCall('cancelPreviousRequestFetchDOById');
    try {
      dispatch({type: FETCH_DO_BY_ID});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        cancelToken: cancelApiCall(),
        url: DO.fetchDOByIdURL(doId, search) + addtripId,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: FETCH_DO_BY_ID_COMPLETED, payload: {...data, search}});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_DO_BY_ID_FAILED,
          payload: {
            error: Object.keys(response?.data).length === 0 ? '' : message,
          },
        });
      }
    } catch (err) {
      let errorMessage = err.message;
      if (axios.isCancel(err)) {
        dispatch({
          type: FETCH_DO_LIST_CANCELED,
          payload: true,
        });
        return {status: false, isFetchDOListCanceled: true};
      } else {
        if (response.status === 500) {
          errorMessage = 'Internal Server error';
        }
        dispatch({
          type: FETCH_DO_BY_ID_FAILED,
          payload: {error: errorMessage},
        });
      }
    }
    return response;
  };
};

export const terminateNewOpenBooking = (doId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: 'TERMINATE_DO_BY_ID', payload: doId});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: `/transin/terminate-booking`,
        data: {
          booking_id: doId,
        },
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: 'UPDATE_DO_BY_ID',
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'TRIP_TERMINATE_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'TRIP_TERMINATE_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const terminatetripCreated = (id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: 'TERMINATE_TRIP_CREATED', payload: id});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: `transin/terminate-trip`,
        data: {
          trip_id: id,
        },
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: 'UPDATE_TRIP_CREATED',
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'TRIP_TERMINATE_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'TRIP_TERMINATE_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const deleteOrCloseDO = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DELETE_OR_CLOSE_DO});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: DO.deleteOrCloseDOURL(),
        data: requestData,
        isMAPI: true,
      });
      console.log('response --DELETE_OR_CLOSE_DO--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: DELETE_OR_CLOSE_DO_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: DELETE_OR_CLOSE_DO_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DELETE_OR_CLOSE_DO_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const releaseLoads = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: RELEASE_LOADS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: DO.releaseLoadsURL(),
        data: requestData,
      });
      console.log('response --RELEASE_LOADS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: RELEASE_LOADS_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: RELEASE_LOADS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: RELEASE_LOADS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const vehicleCheck = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ASSIGN_VEHICLE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: 'vehicle/check-do-vehicle-status',
        data: requestData,
      });
      const {data, status} = response || {};
    } catch (err) {}
    return response;
  };
};

export const assignVehicle = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ASSIGN_VEHICLE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: DO.assignVehicleURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: ASSIGN_VEHICLE_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: ASSIGN_VEHICLE_FAILED,
          payload: message,
        });
      }
    } catch (err) {
      dispatch({
        type: ASSIGN_VEHICLE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const recordShortageData = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: RECORD_SHORTAGE_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: DO.recordShortageDataURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: RECORD_SHORTAGE_DATA_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: RECORD_SHORTAGE_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: RECORD_SHORTAGE_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const deleteTripPOD = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DELETE_TRIP_POD});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: DO.deleteTripPODURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DELETE_TRIP_POD_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DELETE_TRIP_POD_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DELETE_TRIP_POD_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case DELETE_TRIP_POD:
      return {...state, deletingTripPOD: true};

    case 'FETCH_SUMMARY':
      return {
        ...state,
        fetchSummary: true,
      };

    case 'FETCH_SUMMARY_COMPLETED':
      return {
        ...state,
        fetchSummary: false,
        summary: payload,
      };
    case DELETE_TRIP_POD_COMPLETED:
      return {
        ...state,
        deletingTripPOD: false,
      };
    case DELETE_TRIP_POD_FAILED:
      return {...state, deletingTripPOD: false};
    case CREATE_DO:
    case 'EDIT_DO':
      return {...state, isFetching: true};
    case HANDLE_CLEAR_SEARCH:
      return {
        ...state,
        onClearSearch: payload,
      };

    case CALL_DO_DATA_API:
      return {
        ...state,
        callDoDataAPI: payload,
      };

    case EMPTY_SEARCH_INPUT_DATA:
      return {
        ...state,
        searchFeildData: payload,
      };
    case STORE_NEW_BENEFICIARY_ID:
      return {
        ...state,
        newBeneficiaryId: payload,
      };
    case CREATE_DO_COMPLETED:
      return {
        ...state,
        isFetching: false,
      };
    case CREATE_DO_FAILED:
      return {...state, isFetching: false};
    case RELEASE_LOADS:
      return {
        ...state,
        requestingReleaseLoads: true,
      };
    case RELEASE_LOADS_COMPLETED:
      return {
        ...state,
        requestingReleaseLoads: false,
        doByIdData: {},
      };
    case RELEASE_LOADS_FAILED:
      return {
        ...state,
        requestingReleaseLoads: false,
      };

    case ASSIGN_VEHICLE:
      return {
        ...state,
        assignVehicleLoading: true,
      };
    case ASSIGN_VEHICLE_COMPLETED:
      return {
        ...state,
        assignVehicleLoading: false,
      };
    case ASSIGN_VEHICLE_FAILED:
      return {
        ...state,
        assignVehicleLoading: false,
      };

    case FETCH_DO_BY_ID:
    case 'SAVE_TDS':
      return {...state, isFetchingDOById: true};
    case FETCH_DO_BY_ID_COMPLETED:
      return {
        ...state,
        isFetchingDOById: false,
        doByIdData: payload,
      };

    case 'TERMINATE_TRIP_CREATED': {
      return {
        ...state,
        terminateTripById: true,
        terminatedBtnTripLoader: payload,
      };
    }

    case 'UPDATE_TRIP_CREATED':
      let terminate_trip = state.doByIdData.trips_data;
      let AllTerminatedTrip = state.doByIdData.terminated_trip_data;
      let moveToTerminatedTrip = state.doByIdData.trips_data.filter(
        (item) => item.trip_id == payload.trip_id,
      );

      if (moveToTerminatedTrip.length > 0) {
        AllTerminatedTrip.push(moveToTerminatedTrip[0]);
      }

      let getUpdatedTripData = terminate_trip.filter(
        (item) => item.trip_id != payload.trip_id,
      );
      let newDataTrip = {
        ...state.doByIdData,
        trips_data: getUpdatedTripData,
        terminated_trip_data: AllTerminatedTrip,
        terminated_trip_count: AllTerminatedTrip.length,
        trips_count: state.doByIdData.trips_count - 1,
      };
      console.log('NEW DATA', newDataTrip);
      return {
        ...state,
        doByIdData: {
          ...newDataTrip,
        },
        terminateTripById: false,
        terminatedBtnTripLoader: '',
      };

    case 'TERMINATE_DO_BY_ID': {
      return {
        ...state,
        terminateDOById: true,
        terminatedBtnLoader: payload,
      };
    }

    case 'UPDATE_DO_BY_ID':
      let terminate = state.doByIdData.open_booking_data;
      let getUpdatedData = terminate.filter(
        (item) => item.booking_id != payload.booking_id,
      );
      let newData = {
        ...state.doByIdData,
        open_booking_data: getUpdatedData,
        open_booking_count: state.doByIdData.open_booking_count - 1,
      };
      return {
        ...state,
        doByIdData: {
          ...newData,
        },
        terminateDOById: false,
        terminatedBtnLoader: '',
      };

    case 'TRIP_TERMINATE_FAILED': {
      return {
        ...state,
        terminateTripById: false,
        terminatedBtnTripLoader: '',
        terminateDOById: false,
        terminatedBtnLoader: '',
      };
    }
    case FETCH_DO_BY_ID_FAILED:
      return {...state, isFetchingDOById: false, doByIdError: payload.error};
    case FETCH_DO_LIST:
      return {
        ...state,
        isFetchingDOList: true,
        hasError: false,
        error: '',
      };
    case FETCH_DO_LIST_COMPLETED:
      return {
        ...state,
        isFetchingDOList: false,
        doData: payload,
        search: payload.search,
        hasError: false,
        filters: payload?.filters,
        InActiveVehicle: payload?.inactive_vehicle,
        IsUnavailableVehicle: payload?.is_unavailable_vehicle,
        UnApprovedVehicle: payload?.unapproved_vehicle,
      };
    case FETCH_DO_LIST_FAILED:
      return {
        ...state,
        isFetchingDOList: false,
        hasError: true,
        doListError: payload.error,
      };
    case FETCH_DO_LIST_CANCELED:
      return {
        ...state,
        isFetchDOListCanceled: true,
      };
    case FETCH_DO_INITIAL_DATA:
      return {
        ...state,
        isFetchingDOInitial: true,
        hasError: false,
        error: '',
      };
    case FETCH_DO_INITIAL_DATA_COMPLETED:
      return {
        ...state,
        isFetchingDOInitial: false,
        doInitialData: payload,
        hasError: false,
      };
    case ADD_CITY_RECORD_COMPLETED:
      return {
        ...state,
        doInitialData: {
          ...state.doInitialData,
          cities: payload.cities || state.doInitialData.cities,
          city_ids: payload.city_ids || state.doInitialData.city_ids,
        },
      };
    case FETCH_DO_INITIAL_DATA_FAILED:
      return {
        ...state,
        isFetchingDOInitial: false,
        hasError: true,
        error: payload.error,
      };
    case FETCH_CONSIGNORS_BY_CUSTOMER:
      return {...state, isRequestingConsignors: true};
    case FETCH_CONSIGNORS_BY_CUSTOMER_COMPLETED:
      return {
        ...state,
        isRequestingConsignors: false,
      };
    case FETCH_CONSIGNORS_BY_CUSTOMER_FAILED:
      return {
        ...state,
        isRequestingConsignors: false,
        error: payload?.error,
        ...payload,
      };
    // case RESET_PAGE:
    //   return {
    //     ...state,
    //     resetPage: !state.resetPage,
    //   };
    default:
      return state;
  }
};
