import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Reports} from '../../../helpers/Urls';

const intialState = {
  isFetching: false,
  isFetchingTripsTable: false,
  isDownloadingTripsReport: false,
  customerReport: {},
};

export const FETCH_DAILY_CUSTOMER_TRIPS = 'FETCH_DAILY_CUSTOMER_TRIPS';
export const FETCH_DAILY_CUSTOMER_TRIPS_COMPLETED =
  'FETCH_DAILY_CUSTOMER_TRIPS_COMPLETED';
export const FETCH_DAILY_CUSTOMER_TRIPS_FAILED =
  'FETCH_DAILY_CUSTOMER_TRIPS_FAILED';
export const FETCH_DAILY_CUSTOMER_TRIPS_TABLE =
  'FETCH_DAILY_CUSTOMER_TRIPS_TABLE';
export const FETCH_DAILY_CUSTOMER_TRIPS_TABLE_COMPLETED =
  'FETCH_DAILY_CUSTOMER_TRIPS_TABLE_COMPLETED';
export const FETCH_DAILY_CUSTOMER_TRIPS_TABLE_FAILED =
  'FETCH_DAILY_CUSTOMER_TRIPS_TABLE_FAILED';

export const DOWNLOAD_DAILY_CUSTOMER_TRIPS = 'DOWNLOAD_DAILY_CUSTOMER_TRIPS';
export const DOWNLOAD_DAILY_CUSTOMER_TRIPS_COMPLETED =
  'DOWNLOAD_DAILY_CUSTOMER_TRIPS_COMPLETED';
export const DOWNLOAD_DAILY_CUSTOMER_TRIPS_FAILED =
  'DOWNLOAD_DAILY_CUSTOMER_TRIPS_FAILED';

export const fetchDailyCustomerReport = (type = '') => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DAILY_CUSTOMER_TRIPS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Reports.fetchDailyCustomerReportURL(type),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_DAILY_CUSTOMER_TRIPS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_DAILY_CUSTOMER_TRIPS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_DAILY_CUSTOMER_TRIPS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchDailyCustomerTripReport = (requestData) => {
  console.log('REQUEST DATA', requestData);
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DAILY_CUSTOMER_TRIPS_TABLE});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Reports.fetchDailyCustomerTripReportURL(),
        params: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_DAILY_CUSTOMER_TRIPS_TABLE_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_DAILY_CUSTOMER_TRIPS_TABLE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_DAILY_CUSTOMER_TRIPS_TABLE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const downloadDailyCustomerTripReport = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_DAILY_CUSTOMER_TRIPS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Reports.fetchDownloadCustomerTripReportURL(),
        params: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DOWNLOAD_DAILY_CUSTOMER_TRIPS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DOWNLOAD_DAILY_CUSTOMER_TRIPS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_DAILY_CUSTOMER_TRIPS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export default (state = intialState, action) => {
  const {type, payload = {}} = action || {};
  switch (type) {
    case FETCH_DAILY_CUSTOMER_TRIPS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_DAILY_CUSTOMER_TRIPS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        error: '',
        customerReport: Object.assign({}, payload),
      };
    case FETCH_DAILY_CUSTOMER_TRIPS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload?.error,
        customerReport: {},
      };

    case FETCH_DAILY_CUSTOMER_TRIPS_TABLE:
      return {
        ...state,
        isFetchingTripsTable: true,
      };
    case FETCH_DAILY_CUSTOMER_TRIPS_TABLE_COMPLETED:
      return {
        ...state,
        isFetchingTripsTable: false,
        customerTripReport: Object.assign({}, payload),
      };
    case FETCH_DAILY_CUSTOMER_TRIPS_TABLE_FAILED:
      return {
        ...state,
        isFetchingTripsTable: false,
        customerTripReport: {},
      };
    case DOWNLOAD_DAILY_CUSTOMER_TRIPS:
      return {
        ...state,
        isDownloadingTripsReport: true,
        downloadURL: '',
      };
    case DOWNLOAD_DAILY_CUSTOMER_TRIPS_COMPLETED:
      return {
        ...state,
        isDownloadingTripsReport: false,
        downloadURL: '',
      };
    case DOWNLOAD_DAILY_CUSTOMER_TRIPS_FAILED:
      return {
        ...state,
        isDownloadingTripsReport: false,
        downloadURL: '',
      };
    default:
      return state;
  }
};
