import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE, RECORD_TYPE} from '../../../constants';
import * as _ from 'lodash';
import {Reports} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetchingTable: false,
  isFetching: false,
  isDownloading: false,
  hasError: false,
  error: '',
  vehicle_type_table: [],
  vehicle_record: [],
  download: false,
};

export const FETCH_CONTRACTED_VEHICLE_DETAILS =
  'FETCH_CONTRACTED_VEHICLE_DETAILS';
export const FETCH_CONTRACTED_VEHICLE_DETAILS_COMPLETED =
  'FETCH_CONTRACTED_VEHICLE_DETAILS_COMPLETED';
export const FETCH_CONTRACTED_VEHICLE_DETAILS_FAILED =
  'FETCH_CONTRACTED_VEHICLE_DETAILS_FAILED';
export const DOWNLOAD_CONTRACTED_VEHICLE_REPORT =
  'DOWNLOAD_CONTRACTED_VEHICLE_REPORT';
export const DOWNLOAD_CONTRACTED_VEHICLE_REPORT_COMPLETED =
  'DOWNLOAD_CONTRACTED_VEHICLE_REPORT_COMPLETED';
export const DOWNLOAD_CONTRACTED_VEHICLE_REPORT_FAILED =
  'DOWNLOAD_CONTRACTED_VEHICLE_REPORT_FAILED';
export const FETCH_CONTRACTED_VEHICLE_TABLE = 'FETCH_CONTRACTED_VEHICLE_TABLE';
export const FETCH_CONTRACTED_VEHICLE_TABLE_COMPLETED =
  'FETCH_CONTRACTED_VEHICLE_TABLE_COMPLETED';
export const FETCH_CONTRACTED_VEHICLE_TABLE_FAILED =
  'FETCH_CONTRACTED_VEHICLE_TABLE_FAILED';

export const downloadContractedVehicleReport = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_CONTRACTED_VEHICLE_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Reports.fetchContractedVehicleTableURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DOWNLOAD_CONTRACTED_VEHICLE_REPORT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DOWNLOAD_CONTRACTED_VEHICLE_REPORT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_CONTRACTED_VEHICLE_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchContractedVehicleReport = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_CONTRACTED_VEHICLE_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Reports.fetchContractedVehicleURL(),
      });
      // console.log('response --FETCH_VENDOR_DETAILS_COMPLETED--', response);
      const {data, status} = response || {};
      //   if (resetPage) {
      //     dispatch({type: RESET_PAGE});
      //   }

      if (status === true) {
        dispatch({
          type: FETCH_CONTRACTED_VEHICLE_DETAILS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_CONTRACTED_VEHICLE_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_CONTRACTED_VEHICLE_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const fetchContractedVehicleTable = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_CONTRACTED_VEHICLE_TABLE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Reports.fetchContractedVehicleTableURL(),
        data: requestData,
      });
      // console.log('response --FETCH_VENDOR_DETAILS_COMPLETED--', response);
      const {data, status} = response || {};
      //   if (resetPage) {
      //     dispatch({type: RESET_PAGE});
      //   }

      if (status === true) {
        dispatch({
          type: FETCH_CONTRACTED_VEHICLE_TABLE_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_CONTRACTED_VEHICLE_TABLE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_CONTRACTED_VEHICLE_TABLE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_CONTRACTED_VEHICLE_DETAILS:
      return {
        ...state,
        error: '',
        isFetching: true,
        hasError: false,
        vehicle_type_table: [],
      };
    case FETCH_CONTRACTED_VEHICLE_DETAILS_COMPLETED:
      console.log(' payload?.vehicle_type_table', payload?.vehicle_type_table);

      return {
        ...state,
        isFetching: false,
        hasError: false,
        vehicle_type_table: payload?.vehicle_type_table,
      };
    case FETCH_CONTRACTED_VEHICLE_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.message,
        vehicle_type_table: [],
      };
    case DOWNLOAD_CONTRACTED_VEHICLE_REPORT:
      return {
        ...state,
        error: '',
        isDownloading: true,
        hasError: false,
      };
    case DOWNLOAD_CONTRACTED_VEHICLE_REPORT_COMPLETED:
      return {
        ...state,
        isDownloading: false,
        hasError: false,
      };
    case DOWNLOAD_CONTRACTED_VEHICLE_REPORT_FAILED:
      return {
        ...state,
        isDownloading: false,
        hasError: true,
        error: payload.message,
      };
    case FETCH_CONTRACTED_VEHICLE_TABLE:
      return {
        ...state,
        error: '',
        isFetchingTable: true,
        hasError: false,
        vehicle_record: [],
        download: false,
      };
    case FETCH_CONTRACTED_VEHICLE_TABLE_COMPLETED:
      return {
        ...state,
        isFetchingTable: false,
        hasError: false,
        vehicle_record: payload?.vehicle_record,
        download: payload?.download,
      };
    case FETCH_CONTRACTED_VEHICLE_TABLE_FAILED:
      return {
        ...state,
        isFetchingTable: false,
        hasError: true,
        error: payload.message,
        vehicle_record: [],
        download: false,
      };
    default:
      return state;
  }
};
